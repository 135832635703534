@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slider {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: transparent;
}

.slide-track {
  display: flex;
  gap: 8rem; /* 128px d'espacement entre les logos */
  animation: scroll 20s linear infinite;
  width: fit-content;
}

.slide {
  flex: 0 0 auto;
  width: 176px; /* Équivalent à w-44 */
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-100% / 3));
  }
}

/* Optionnel : Pause au survol */
.slide-track:hover {
  animation-play-state: paused;
}

/* Pour assurer une animation fluide */
@media (prefers-reduced-motion: no-preference) {
  .slide-track {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transform-style: preserve-3d;
  }
}